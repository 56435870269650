<template>
  <div class="leason-container">
    <div class="course-card-title">高数数学课程（高中）</div>
    <div class='course-unit'>
      <span>课程章节：</span>
      <div>
        <div v-for="i in 5" :key="i">第一章</div>
      </div>
    </div>
    <div>第一章/第一小节</div>
    <div>函数得定义</div>
    <a>课程资料下载</a>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>